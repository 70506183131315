import Swal from 'sweetalert2';

export default function GetEvents(setCards, setEvents) {
     fetch(`${process.env.REACT_APP_API_URL}eventos_lista.php?test=${process.env.REACT_APP_MODE}`)
          .then((response) => response.json())
          .then((data) => {
               setEvents(data);
               setCards(data);
          })
          .catch(() => {
               Swal.fire('Ocurrio un error', 'Vuelva a intentarlo más tarde.', 'error');
          });
}
