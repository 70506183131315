import React, {useState, useEffect, Suspense, lazy} from 'react';
import {BrowserRouter as Router, Route, Routes, Navigate} from 'react-router-dom';
import GetEvents from './Services/GetEvents';
import {BufferSpinner} from './components/Complementos/BufferSpinner';
import {Provider} from 'react-redux';
import store from './redux/store';
import {useLocation} from 'react-router-dom';
//Components
const Eventos = lazy(() => import('./components/Eventos'));
const CodVerificar = lazy(() => import('./components/CodVerificar'));
const Registro = lazy(() => import('./components/Registro'));
const InfoEvento = lazy(() => import('./components/InfoEvento'));
const Navbar = lazy(() => import('./components/Navbar'));
const SolicitarEntradas = lazy(() => import('./components/SolicitarEntradas/SolicitarEntradas'));
const Footer = lazy(() => import('./components/Footer'));
const Page404 = lazy(() => import('./components/Page404'));
const LoginPanel = lazy(() => import('./components/Admin/LoginPanel'));
const PanelAdmin = lazy(() => import('./components/Admin/PanelAdmin'));
const AdminEvento = lazy(() => import('./components/Admin/AdminEvento'));
const CheckUsers = lazy(() => import('./components/Admin/CheckUsers'));
const CreateEvento = lazy(() => import('./components/Admin/CreateEvento'));

function App() {
     //Estados estaticos
     const [cards, setCards] = useState([]);
     const [events, setEvents] = useState([]);
     const [userAdmin, setUserAdmin] = useState(null);
     const [userLogged, setUserLogged] = useState(null);

     useEffect(() => {
          const loggedUserJSON = window.localStorage.getItem('userLogin');
          const adminUserJSON = window.localStorage.getItem('userAdmin');

          if (loggedUserJSON) {
               const userL = JSON.parse(loggedUserJSON);
               setUserLogged(userL);
          }

          if (adminUserJSON) {
               const userA = JSON.parse(adminUserJSON);
               setUserAdmin(userA);
          }

          GetEvents(setCards, setEvents);
     }, []);

     const ProtectedRouteLogin = ({children}) => {
          return userLogged ? <Navigate to='/culturales/' /> : children;
     };

     const ProtectedRouteWithRedirect = ({children}) => {
          const location = useLocation();
          const idEvento = location.pathname.split('/').pop();
          const loggedUserJSON = window.localStorage.getItem('userLogin');

          if (!loggedUserJSON) {
               return <Navigate to={`/culturales/registro/${idEvento}`} />;
          }

          return children;
     };

     const ProtectedRouteAdmin = ({children}) => {
          const adminUserJSON = window.localStorage.getItem('userAdmin');
          if (adminUserJSON) {
               return <Navigate to='/culturales/panel-admin/' />;
          }

          return children;
     };

     const ProtectedUserAdmin = ({children}) => {
          const adminUserJSON = window.localStorage.getItem('userAdmin');
          if (!adminUserJSON) {
               return <Navigate to='/culturales/login-panel/' />;
          }

          return children;
     };

     return (
          <div className='App'>
               <Provider store={store}>
                    <Router>
                         <Navbar />
                         <Suspense fallback={<BufferSpinner />}>
                              <Routes>
                                   <Route path='/culturales/' element={<Eventos cards={cards} />} />

                                   <Route
                                        path='/culturales/evento/:idEvento'
                                        element={<InfoEvento events={events} />}
                                   />

                                   <Route
                                        path='/culturales/registro/:idEvento'
                                        element={
                                             <ProtectedRouteLogin>
                                                  <Registro />
                                             </ProtectedRouteLogin>
                                        }
                                   />

                                   <Route
                                        path='/culturales/cod-verificacion/:idEvento'
                                        element={<CodVerificar />}
                                   />

                                   <Route
                                        path='/culturales/solicitar-entrada/:idEvento'
                                        element={
                                             <ProtectedRouteWithRedirect>
                                                  <SolicitarEntradas />
                                             </ProtectedRouteWithRedirect>
                                        }
                                   />

                                   <Route
                                        path='/culturales/login-panel/'
                                        element={
                                             <ProtectedRouteAdmin>
                                                  <LoginPanel />
                                             </ProtectedRouteAdmin>
                                        }
                                   />

                                   <Route
                                        path='/culturales/panel-admin/'
                                        exact
                                        element={<PanelAdmin />}
                                   />

                                   <Route path='/culturales/admin-evento'>
                                        <Route
                                             path=':idEvento'
                                             element={
                                                  <ProtectedUserAdmin>
                                                       <AdminEvento events={events} />
                                                  </ProtectedUserAdmin>
                                             }
                                        />
                                   </Route>

                                   <Route
                                        path='/culturales/create-evento/'
                                        exact
                                        element={
                                             <ProtectedUserAdmin>
                                                  <CreateEvento />
                                             </ProtectedUserAdmin>
                                        }
                                   />

                                   <Route path='/culturales/check-users'>
                                        <Route
                                             path=':idEvento'
                                             element={<CheckUsers events={events} />}
                                        />
                                   </Route>

                                   <Route path='*' element={<Page404 />} />
                              </Routes>
                              <Footer />
                         </Suspense>
                    </Router>
               </Provider>
          </div>
     );
}

export default App;
