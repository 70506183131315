import {createSlice} from '@reduxjs/toolkit';
import {
     clearLocalStorage,
     setLocalStorage,
     getLocalStorage,
} from '../../utilities/localStorageTools';

export const User = null;

export const userSlice = createSlice({
     name: 'userLogin',
     initialState: getLocalStorage('userLogin') ? JSON.parse(getLocalStorage('userLogin')) : User,
     reducers: {
          loadUser: (state, action, name = 'userLogin') => {
               const data = action.payload;
               setLocalStorage(name, data);
               return data;
          },
          modifyUser: (state, action, name = 'userLogin') => {
               const data = {...state, ...action.payload};
               setLocalStorage(name, data);
               return data;
          },
          resetUser: (name = 'userLogin') => {
               clearLocalStorage(name);
               return User;
          },
     },
});

export const {loadUser, modifyUser, resetUser} = userSlice.actions;
